
.chart-size{
    /* width: 1100px !important; */
    /* height: 500px !important; */
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
}

.no-data-small{
    width: 300px !important;
    height: 300px !important;
}

.w-60 {
    max-width: 60% !important;
    width: 60% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    max-width: 80% !important;
    width: 80% !important;
}