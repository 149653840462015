/* modal */
.modal-footer {
    border-top: none !important;
}

/* buttons */
.button-secondary,
.button-primary,
.button-tertiary,
.button-warning,
.dropdown-toggle btn btn-primary,
.button-warning-fill {
    font-family: var(--primary-font-medium);
    font-size: 12px;
    line-height: 18px;

    text-transform: capitalize;
    letter-spacing: 0.06em;

    border-radius: 7px;
    box-shadow: none;
    width: 9vw;
    height: 4vh;
}

.dropdown-toggle btn btn-primary {
    width: "100%" !important;
    
}

.dropdown-item {
    font-family: var(--primary-font-medium);
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    letter-spacing: 0.06em;
}

.button-primary:hover,
.button-primary:focus,
.button-primary:active,
.button-secondary:hover,
.button-secondary:focus,
.button-secondary:active,
.button-tertiary:hover,
.button-tertiary:focus,
.button-tertiary:active,
.button-warning:hover,
.button-warning:focus,
.button-warning-fill:active,
.button-warning-fill:hover,
.button-warning-fill:focus,
.button-warning-fill:active,
.dropdown-toggle btn btn-primary {
    box-shadow: none;
}

.button-primary,
.dropdown-toggle btn btn-primary {
    background: #5ac8e1 !important;
    border: none !important;
    color: white;
}

.button-primary:disabled {
    background: rgb(212, 209, 209) !important;
    cursor: not-allowed;
  }

.button-primary:hover,
.dropdown-toggle btn btn-primary {
    background: #5ac8e1;
    border: none;
    color: white;
}

.dropdown-toggle btn btn-primary {
    border-radius: 0 !important;
}

.button-primary-clear {
    background: white;
    border: 2px solid #5ac8e1;
    color: #5ac8e1;
}

.button-primary-clear:hover {
    background: #5ac8e1;
    border: none;
    color: white;
}

.button-secondary {
    background: white;
    border: 1px solid #0077b6;
    color: #0077b6;
}

/* .button-secondary:hover {
    background: #0077b6;
    border: 1px solid #0077b6;
    color: white;
} */

.button-tertiary {
    background: #0077b6;
    border: 1px solid #0077b6;
    color: white;
}

.button-tertiary:hover {
    background: #0077b6;
    border: 1px solid #0077b6;
}

.button-warning {
    background: white;
    border: 1px solid #DC3545;
    color: #DC3545;
}

.button-warning:hover {
    border: 2px solid #DC3545;
    font-weight: 600;
}

.button-warning-fill {
    background: #DC3545;
    border: 1px solid #DC3545;
    color: white;
}

.button-warning-fill:hover {
    background: #df1227;
    border: 1px solid #df1227;
}

.w-90 {
    width: 95% !important;
}

.react-select__menu,
.react-select__value-container {
    font-size: 1vw !important;
}

.ant-select {
    font-family: var(--primary-font-medium) !important;
}