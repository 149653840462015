.app-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100%;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;

  }
  
  th,
  .custom-td {
    border: 1px solid #000000;
    text-align: left;
    padding: 8px;
    /* font-size: 32px; */
    border: collapse;
  }
  .custom-desc-td{
    border: 1px solid #000000;
    text-align: left !important;
    padding: 8px;
    font-size: 32px;
    border: collapse;
    
  }
  .custom-num-td{
    border: 1px solid #000000;
    text-align: right !important;
    padding: 8px;
    font-size: 32px;
    border: collapse;
  }
  
  
  th {
    order: 1px solid black;
  }
  
  td {
    border-collapse: collapse;
    background-color: white;
    border: 5px solid black;

  }
  

  .wht-dropdown {
    width: 100% !important;        /* Ensures the dropdown only takes the space it needs */
    max-width: 150px !important;   /* Optional: Set a maximum width to prevent it from becoming too wide */
    margin-left: 8px !important;   /* Space between the "WHT" label and the dropdown */
    padding: 0.375rem 0.75rem !important; /* Padding to make the dropdown look consistent */
    font-size: 0.875rem !important;
    font-weight: bold !important; /* Adjust font size for better alignment with the label */
    display: inline-block !important; /* Makes it sit inline next to the label */
  }
  
  
  form {
    display: flex;
    gap: 5px;
  }
  
  form td:last-child {
    display: flex;
    justify-content: space-evenly;
  }
  
  form * {
    font-size: 28px;
  }

  .custom-row {
    background-color: #f2f8ff; /* Light blue background color */
  }

  /* .print-table-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
   */
  .react-datepicker__aria-live {
    display: none !important;
  }

  .print-table {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  


  


  