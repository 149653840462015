.add-type-btn {
    border: none;
    border-radius: 5px;
    background-color: #5ac8e1;
    color: #FFFFFF; 
}

.TabStyle1 {
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: stretch;
    margin: 0;
    padding: 0;
  }
  
.TabStyle2 {
    flex: 1;
    text-align: center;
  }

  .margin-top-5 {
    margin-top: 5vh !important;
  }

/* Ensure the "TOTAL" column is right-aligned */
.table td.total-column, .table th.total-column {
  text-align: right !important;  /* Right-align the text */
}

