.filter {
    font-family: var(--primary-font-medium);
    font-size: 15px;
    line-height: 18px;
    color: #5E5E5E;
    text-transform: uppercase;

    /* background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 0px 5px 5px 5px;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.2);
    max-height: 77vh; */
}
/* .radio-add-project{
} */
.franchise-td-green {
    width: 150px;
    font-family: var(--primary-font-medium);
    font-size: small;
    font-weight: bold;
    line-height: 18px;
    color: black;
    text-transform: uppercase;
}
.right-align{
    text-align: right;
}
.franchise-td-gray {
    width: 150px;
    font-family: var(--primary-font-medium);
    font-size: small;
    line-height: 18px;
    color: #5E5E5E;
    text-transform: uppercase;
}

.franchise-table-view {
    background: #FFFFFF;
    border: 1px solid #B9B9B9;
    border-radius: 10px;
    align-self: center;
}

.text-left {
    text-align: left !important;
}

.css-1pahdxg-control {
    border-color: #5ac8e1 !important;
    box-shadow: 0 0 0 1px #5ac8e1 !important;
}

.nc-modal-custom-suggestion {
  margin-top: 7px;
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid #B9B9B9;
  border-radius: 4px;
  background-color: #FFFFFF !important;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  padding: 0.375rem 0.75rem;
  resize: none;
  border-radius: 10px !important;
}

.nc-modal-custom-suggestion:focus {
    outline: none;
    border: 1px solid #5ac8e1 !important;
    box-shadow: 0 0 0 1px #5ac8e1 !important;
}

.text-right {
    text-align: right !important;
}

.table-row-highlight {
    background-color: yellow;
}

.ml-auto {
    margin-left: auto;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__input-container input::placeholder { color: white; }

.small {
    font-size: .750rem !important;
}

.ml-20 {
    margin-left: 20px;
}

.ml-8 {
    margin-left: 8rem;
}

.ml-4 {
    margin-left: 4rem;
}

.gotham {
    font-family: var(--primary-font-medium);
}

.single-signature {
    margin-right: -700px !important;
}
.amount-label {
  display: inline-block;
  padding-left: 10px; /* Adjust the spacing by modifying this value */
}
.add-project-btn {
    padding-top: 0.5em !important;
    padding-bottom:  0.5em !important;
}
.custom-width{
    width: 90vw !important;
}
.custom-width-amount {
    width: 15vw !important;
}