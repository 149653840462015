.validity-error {
	text-transform: capitalize;
	z-index: 1000 !important;
	font-size: 11px;
	margin-bottom: 0 !important;
	/* padding: 0 !important; */
	/* position: relative; */
	/* margin: 0 !important; */
	font-family: Arial, Helvetica, sans-serif;
	font-weight: lighter !important;
	text-transform:lowercase;
}

.validity-error::first-letter {
	text-transform: capitalize !important;
}

div.callout {
	z-index: 1000 !important;
	font-size: x-small;
	background-color: #8be6f0;
	background-image: -moz-linear-gradient(top,#8be6f0, #8be6f0);
	position: relative;
	color: #212529;
	width: fit-content;
	padding: 2px 8px;
	border-radius: 3px;
	/* box-shadow: 0px 0px 20px #999; */
	border: 1px solid #8be6f0;
	margin-top: 0.2em;
}

.callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
	z-index: 1000 !important;
}

.callout.bottom::before {
	left: 45%;
	top: -19px;
	border-bottom: 10px solid #0077b6;
	z-index: 1000 !important;
}